import { Box, Container, Flex, Heading, Image, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { useAboutCommercialPage } from '../../GraphQL/Queries';
import Layout from '../../components/Layout';
import SEO from '../../components/seo';
import { Hero, SubHero } from '../../components/shared';

const CommercialAboutUsPage = ({ location }) => {
  const data = useAboutCommercialPage();

  const {
    acf_hero,
    acf_text_block_title,
    acf_about_content,
    acf_image_about_container,
    acf_paragraph_title,
  } = data.pageBy;

  return (
    <Layout location={location}>
      <SEO title="About Us" />
      <Hero
        heroCopy={acf_hero.heroDescription}
        heroHeadline={acf_hero.heroTitle}
        noCta={true}
        noIcon={true}
        image={acf_hero.heroImage.sourceUrl}
      />
      <SubHero stacked heading={acf_text_block_title.title} body={acf_text_block_title.content} />
      <Box className="chipped__conainter" mt={{ base: '2rem', md: '5rem' }} mb="2rem" pb="4rem" bg="primary">
        <Container>
          <Flex direction={{ base: 'column-reverse', md: 'row' }} pb="2rem">
            <Box width={{ base: '19.6rem', md: 'unset' }} flex="1" px={{ base: '2rem', md: '0' }}>
              <Heading pt={{ base: '2rem', md: '4rem' }} color="secondary" lineHeight={{ base: '1.15', md: 'unset' }}>
                {acf_about_content.aboutFirstTitle}
              </Heading>
              <Heading
                pr="2rem"
                pb="1rem"
                color="white"
                borderBottomWidth="5px"
                borderBottomColor="white"
                borderBottomStyle="solid"
                lineHeight={{ base: '1.15', md: 'unset' }}
              >
                {acf_about_content.aboutSecondTitle}
              </Heading>
            </Box>
            <Box flex="3" position="relative" zIndex="1">
              <Image
                display={{ base: 'none', md: 'block' }}
                position="absolute"
                top="-60px"
                left="90px"
                src={acf_image_about_container.frontImage.sourceUrl}
                zIndex="1"
                h="277px"
                w="302px"
                objectFit="cover"
                objectPosition="30% 50%"
              />
              <Image
                ml="auto"
                mr="5rem"
                width={{ base: '100%', md: '65%' }}
                height={{ base: '250px', md: 'unset' }}
                objectFit="cover"
                src={acf_image_about_container.backImage.sourceUrl}
                mt="-2px"
              />
            </Box>
          </Flex>
          <Stack
            px={{ base: '2rem', md: '0' }}
            direction={{ base: 'column', md: 'row' }}
            spacing={{ base: '2rem', md: '4rem' }}
          >
            <Box flex="1">
              <Heading color="white">{acf_paragraph_title.primaryTitle}</Heading>
              <Text mt="1rem" color="white" textStyle="p">
                {acf_paragraph_title.primaryContent}
              </Text>
            </Box>
            <Box flex="1">
              <Heading color="white">{acf_paragraph_title.secondTitle}</Heading>
              <Text mt="1rem" color="white" textStyle="p">
                {acf_paragraph_title.secondContent}
              </Text>
            </Box>
          </Stack>
        </Container>
      </Box>
    </Layout>
  );
};

export default CommercialAboutUsPage;
